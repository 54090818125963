import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { distinctUntilChanged } from 'rxjs';

import {
  SendUserVariableTypeEnum,
  SendContentVariableTypeEnum,
  ContentSearchResults,
  ContentType,
  Content,
  SendVariable,
  SendVariableDisplayTextEnum,
  SendCustomVariableTypeEnum,
  isRequiredValidator
} from 'models';

import { BaseComponent } from '../../../models';
import { MockContentService } from '../../../services/content/content.service';

@Component({
  selector: 'norby-sends-variable-selector',
  templateUrl: './norby-sends-variable-selector.component.html',
  styleUrls: ['./norby-sends-variable-selector.component.less']
})
export class NorbySendsVariableSelectorComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  @Input() contentVariableOptions: ContentSearchResults[];
  @Input() contentVariableDefaultOptions: ContentSearchResults[];
  @Input() shouldDisplayDefaultOptions: boolean;
  @Input() editingVariable: SendVariable;
  @Input() isSendField: boolean = true;
  @Input() isVisible: boolean = true;
  @Input() showHtmlVariables: boolean = true;
  @Input() isSearching: boolean = false;

  @Output() onAddUserVariable = new EventEmitter<SendVariable>();
  @Output() onEditUserVariable = new EventEmitter<SendVariable>();
  @Output() onAddContentVariable = new EventEmitter<SendVariable>();
  @Output() onEditContentVariable = new EventEmitter<SendVariable>();
  @Output() onContentVariableSearchInput = new EventEmitter<string>();
  @Output() onAddRelativeDateVariable = new EventEmitter<SendVariable>();
  @Output() onEditRelativeDateVariable = new EventEmitter<SendVariable>();

  variableDisplayText: SendVariableDisplayTextEnum;
  formGroup: FormGroup;
  sendUserVariableType = SendUserVariableTypeEnum;
  sendContentVariableType = SendContentVariableTypeEnum;
  sendCustomVariableTypeEnum = SendCustomVariableTypeEnum;
  variableDisplayTextType = SendVariableDisplayTextEnum;
  selectedContent: Content;
  isLoadingContent: boolean = false;

  constructor(
    private _formBuilder: FormBuilder,
    private _content: MockContentService,
    private _cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    this._initForm();
    this._updateVariableDisplay(this.editingVariable?.type);
  }

  ngOnChanges(changes?: SimpleChanges): void {
    if (
      this.formGroup &&
      changes.isVisible &&
      changes.isVisible.currentValue === false
    ) {
      this.selectedContent = null;
      this.variableDisplayText = null;
      this.formGroup.reset();
    }

    if (changes.editingVariable) {
      if (this.formGroup && changes.editingVariable.currentValue?.type) {
        this._updateVariableDisplay(changes.editingVariable.currentValue.type);

        this.formGroup.patchValue({
          type: changes.editingVariable.currentValue?.type
        }),
          { emitEvent: false };
      }
      if (
        this.formGroup &&
        changes.editingVariable.currentValue?.defaultValue
      ) {
        this.formGroup.patchValue({
          defaultValue: changes.editingVariable.currentValue.defaultValue
        }),
          { emitEvent: false };
      }
      if (
        this.formGroup &&
        changes.editingVariable.currentValue?.relativeDateText
      ) {
        this.formGroup.patchValue({
          relativeDateText:
            changes.editingVariable.currentValue.relativeDateText
        }),
          { emitEvent: false };
      }
      if (
        this.formGroup &&
        changes.editingVariable.currentValue?.relativeDateFormat
      ) {
        this.formGroup.patchValue({
          relativeDateFormat:
            changes.editingVariable.currentValue.relativeDateFormat
        }),
          { emitEvent: false };
      }
      if (
        changes.editingVariable.currentValue?.contentId &&
        changes.editingVariable.currentValue?.contentId !==
          this.selectedContent?.contentId
      ) {
        this.isLoadingContent = true;
        this._content
          .getContent(changes.editingVariable.currentValue.contentId)
          .then((content) => {
            this.selectedContent = content;
            this.isLoadingContent = false;
            this._cdr.markForCheck();
          });
      }
    }
  }

  get isUserVariable(): boolean {
    const type = this.formGroup?.get('type')?.value;
    return !!SendUserVariableTypeEnum[type];
  }

  get isContentVariable(): boolean {
    const type = this.formGroup?.get('type')?.value;
    return !!SendContentVariableTypeEnum[type];
  }

  get isRelativeDateVariable(): boolean {
    const type = this.formGroup?.get('type')?.value;
    return (
      SendCustomVariableTypeEnum[type] ===
      SendCustomVariableTypeEnum.RELATIVE_DATE
    );
  }

  getIcon(contentType: ContentType): string {
    return contentType === 'event' ? 'calendar' : 'edit';
  }

  private _initForm(): void {
    this.formGroup = this._formBuilder.group({
      type: [this.editingVariable?.type ?? '', Validators.required],
      defaultValue: [this.editingVariable?.defaultValue ?? ''],
      contentVariableSearchInput: [''],
      relativeDateText: [
        this.editingVariable?.relativeDateText ?? '',
        isRequiredValidator(
          () =>
            this.formGroup?.get('type')?.value ===
            SendCustomVariableTypeEnum.RELATIVE_DATE
        )
      ],
      relativeDateFormat: [
        this.editingVariable?.relativeDateFormat ?? '',
        isRequiredValidator(
          () =>
            this.formGroup?.get('type')?.value ===
            SendCustomVariableTypeEnum.RELATIVE_DATE
        )
      ]
    });

    this.formGroup.controls['contentVariableSearchInput'].valueChanges
      .pipe(distinctUntilChanged(), this.takeUntilDestroy)
      .subscribe((value) => {
        this.onContentVariableSearchInput.emit(value);
      });
  }

  private _updateVariableDisplay(type: string) {
    if (type) {
      this.variableDisplayText = SendVariableDisplayTextEnum[type];
    } else {
      this.variableDisplayText = null;
    }
  }

  handleSelectVariable(type: string) {
    this._updateVariableDisplay(type);

    this.formGroup.patchValue({
      type,
      contentVariableSearchInput: ''
    });
    this.selectedContent = null;
  }

  handleSelectContent(content: Content) {
    this.selectedContent = content;
  }

  handleChangeContent() {
    this.selectedContent = null;
    const currentQuery = this.formGroup.get(
      'contentVariableSearchInput'
    )?.value;
    this.formGroup.patchValue({ contentVariableSearchInput: currentQuery });
  }

  handleAddVariable() {
    const type = this.formGroup.get('type')?.value;
    const defaultValue = this.formGroup.get('defaultValue')?.value;
    const relativeDateText = this.formGroup.get('relativeDateText')?.value;
    const relativeDateFormat = this.formGroup.get('relativeDateFormat')?.value;

    if (this.isUserVariable) {
      this.editingVariable
        ? this.onEditUserVariable.emit({
            type,
            defaultValue
          })
        : this.onAddUserVariable.emit({
            type,
            defaultValue
          });
    }

    if (this.isContentVariable) {
      this.editingVariable
        ? this.onEditContentVariable.emit({
            type,
            contentId: this.selectedContent?.contentId
          })
        : this.onAddContentVariable.emit({
            type,
            contentId: this.selectedContent?.contentId
          });
    }

    if (this.isRelativeDateVariable) {
      this.editingVariable
        ? this.onEditRelativeDateVariable.emit({
            type,
            relativeDateText,
            relativeDateFormat
          })
        : this.onAddRelativeDateVariable.emit({
            type,
            relativeDateText,
            relativeDateFormat
          });
    }
  }
}
