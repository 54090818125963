import { Type, instanceToPlain, plainToInstance } from 'class-transformer';

import { PageInfo } from './general';
import { IContact } from './IContact';
import {
  Segment,
  SegmentContactPreview,
  ISegmentFilterGroupDTO
} from './ISegment';

// All possible columns for contact lists
export type ContactListColumn =
  | 'tags'
  | 'altPhotoUrl'
  | 'hasOptedIntoEmailMarketing'
  | 'hasOptedIntoSmsMarketing'
  | 'mediums'
  | 'emails'
  | 'phoneNumbers'
  | 'primaryEmail'
  | 'primaryEmailVerified'
  | 'primaryPhoneNumber'
  | 'photoUrl'
  | 'verifiedEmails'
  | 'name';

// Display names for contact list columns
export const CONTACT_LIST_COLUMN_NAME: { [key in ContactListColumn]: string } =
  {
    altPhotoUrl: 'Alternate photo',
    hasOptedIntoEmailMarketing: 'Email marketing',
    hasOptedIntoSmsMarketing: 'SMS marketing',
    mediums: 'Channels',
    emails: 'Email addresses',
    phoneNumbers: 'Phone numbers',
    primaryEmail: 'Primary email',
    primaryEmailVerified: 'Verified email',
    primaryPhoneNumber: 'Primary phone number',
    photoUrl: 'Photo',
    verifiedEmails: 'Verified emails',
    name: 'Name',
    tags: 'Tags'
  };

// Array of all contact list column identifiers
export const CONTACT_LIST_COLUMNS = Object.keys(
  CONTACT_LIST_COLUMN_NAME
) as ContactListColumn[];

// Columns visible to the user in the edit columns modal
export const VISIBLE_CONTACT_LIST_COLUMNS = CONTACT_LIST_COLUMNS.filter(
  (col) => col !== 'photoUrl' && col !== 'altPhotoUrl'
);

// Visibility statuses for contact lists
export enum ContactListVisibilityEnum {
  PRIVATE = 'private',
  PUBLIC = 'public'
}

export type ContactListVisibility = `${ContactListVisibilityEnum}`;

// Default lists can be queried with the same lookup endpoint
// as custom lists, they just have hardcoded IDs
export enum DefaultContactListIds {
  ALL_CONTACT_LIST_ID = 'all',
  SMS_CONTACT_LIST_ID = 'sms',
  EMAIL_CONTACT_LIST_ID = 'email',
  EVENTBRITE_CONTACT_LIST_ID = 'eventbrite',
  KLAVIYO_CONTACT_LIST_ID = 'klaviyo',
  SHOPIFY_CONTACT_LIST_ID = 'shopify',
  GOOGLE_CONTACT_LIST_ID = 'google',
  STRIPE_CONTACT_LIST_ID = 'stripe',
  ZOOM_CONTACT_LIST_ID = 'zoom',
  MAILCHIMP_CONTACT_LIST_ID = 'mailchimp',
  BEEHIIV_CONTACT_LIST_ID = 'beehiiv',
  ATTENTIVE_CONTACT_LIST_ID = 'attentive'
}

export type DefaultContactListIdType = `${DefaultContactListIds}`;

// Default columns for default contact lists
export type ContactListDefaultColumnType = {
  [key in DefaultContactListIdType]?: ContactListColumn[];
};

export type ContactViewFromModal = {
  list: ContactView;
  shouldDelete: boolean;
};

export class ContactListDefaultColumns implements ContactListDefaultColumnType {
  [DefaultContactListIds.ALL_CONTACT_LIST_ID]?: ContactListColumn[];
  [DefaultContactListIds.SMS_CONTACT_LIST_ID]?: ContactListColumn[];
  [DefaultContactListIds.EMAIL_CONTACT_LIST_ID]?: ContactListColumn[];
}

// Base interface for all lists
export class IContactView {
  protected _type: 'default' | 'custom';
  id: string;
  size: number;
  columns: ContactListColumn[];
}

export function isDefaultView(x: ContactView): x is DefaultContactList {
  return x?.isDefault;
}

export function isCustomView(x: ContactView): x is ContactList {
  return x?.isCustom;
}

// Abstract base class for both default and custom lists
export abstract class ContactView extends IContactView {
  protected _type: 'default' | 'custom';

  id: string;
  size: number;
  columns: ContactListColumn[];

  abstract get title(): string;
  abstract get closable(): boolean;
  abstract get canQuery(): boolean;

  @Type(() => Segment)
  segment: Segment;

  clone(withList?: Partial<ContactList>): ContactList {
    const plainObj = instanceToPlain(this);
    const clone = plainToInstance(ContactList, {
      ...plainObj,
      ...withList,
      _type: 'custom'
    });
    return clone;
  }

  get isDefault(): boolean {
    return this._type === 'default';
  }

  get isCustom(): boolean {
    return this._type === 'custom';
  }

  static fromObject(object: IContactView): ContactView {
    switch (object?.['_type']) {
      case 'default':
        return plainToInstance(DefaultContactList, object);
      default:
        return plainToInstance(ContactList, { ...object, _type: 'custom' });
    }
  }
}

// A custom contact list created by a user
export class ContactList extends ContactView {
  protected readonly _type: 'custom';

  id: string;
  slug: string;
  segmentId: string;
  name: string;
  visibility: ContactListVisibility;
  createdBy: string;
  createdAt: Date;
  modifiedAt: Date;
  references?: [];

  @Type(() => Segment)
  segment: Segment;

  get canQuery(): boolean {
    return !!this.segment?.initializationCompletedAt;
  }

  get title(): string {
    return this.name ?? 'New list';
  }

  get closable(): boolean {
    return true;
  }

  addIncludedContact(contact: SegmentContactPreview) {
    this.segment?.addIncludedContact(contact);
  }

  removeIncludedContact(contactId: string) {
    this.segment?.removeIncludedContact(contactId);
  }

  addExcludedContact(contact: SegmentContactPreview) {
    this.segment?.addExcludedContact(contact);
  }

  removeExcludedContact(contactId: string) {
    this.segment?.removeExcludedContact(contactId);
  }
}

// A default contact list (all / SMS / email)
// or dynamic based on connected integrations in
// the next app
export class DefaultContactList extends ContactView {
  protected readonly _type: 'default';
  readonly id: DefaultContactListIdType =
    DefaultContactListIds.ALL_CONTACT_LIST_ID;

  get canQuery(): boolean {
    return true;
  }

  get title(): string {
    switch (this.id) {
      case 'all':
        return 'All';
      case 'email':
        return 'Email';
      case 'sms':
        return 'SMS';
      case 'eventbrite':
        return 'Eventbrite';
      case 'mailchimp':
        return 'Mailchimp';
      case 'klaviyo':
        return 'Klaviyo';
      case 'stripe':
        return 'Stripe';
      case 'zoom':
        return 'Zoom';
      case 'shopify':
        return 'Shopify';
      case 'google':
        return 'Google Analytics';
      case 'beehiiv':
        return 'Beehiiv';
      case 'attentive':
        return 'Attentive';
      default:
        return 'Unknown list';
    }
  }

  get closable(): boolean {
    return false;
  }
}

// The list summary allows us to populate the tabs across the top of
// the contacts table
// It includes pinned lists (both default and custom) along with their
// sizes
export class IContactListListSummaryResponse {
  pinned: ContactView[];
  defaultColumns?: ContactListDefaultColumns;
}

export class ContactListListSummaryResponse extends IContactListListSummaryResponse {
  @Type(() => ContactView, {
    keepDiscriminatorProperty: true,
    discriminator: {
      property: '_type',
      subTypes: [
        { value: DefaultContactList, name: 'default' },
        { value: ContactList, name: 'custom' }
      ]
    }
  })
  pinned: ContactView[];
}

// Args for querying contact lists
export class GetContactListsDto {
  limit = 10;
  after?: string;
  offset?: number;
}

// Response when looking up a list of contact lists
export class ContactListResultEdge {
  cursor?: string;
  offset?: number;

  @Type(() => ContactList)
  node: ContactList;
}

export class ContactListResults {
  pageInfo: PageInfo;

  @Type(() => ContactListResultEdge)
  edges: ContactListResultEdge[];
}

// Args for querying contacts in a given list
export class GetContactListContactsDto {
  limit = 10;
  after?: string;
  offset?: number;
}

// Response when looking up contacts in a given list
export class ContactListContactResultEdge {
  cursor?: string;
  offset?: number;
  node: IContact;
}

export class ContactListContactResults {
  pageInfo: PageInfo;
  edges: ContactListContactResultEdge[];
}

export class UserSlugContactList {
  id: string;
  slug: string;
  userId: string;
  defaultColumns: ContactListColumn[];
  pinnedListIds: string[];
  createdAt: Date;
  modifiedAt: Date;
}

// DTO for creating a new contact list
export class CreateContactListDto {
  name!: string;
  pinned?: boolean;
  visibility?: ContactListVisibility;
  columns?: ContactListColumn[];
  filterGroups?: ISegmentFilterGroupDTO[];
  excludedContactIds?: string[];
  includedContactIds?: string[];
}

// DTO for updating an existing contact list
export class UpdateContactListDto {
  name?: string;
  pinned?: boolean;
  visibility?: ContactListVisibility;
  columns?: ContactListColumn[];
  filterGroups?: ISegmentFilterGroupDTO[];
  excludedContactIds?: string[];
  includedContactIds?: string[];
}

// DTO for cloning an existing contact list
export class CloneContactListDto {
  name?: string;
  visibility?: ContactListVisibility;
  columns?: ContactListColumn[];
  filterGroups?: ISegmentFilterGroupDTO[];
  excludedContactIds?: string[];
  includedContactIds?: string[];
  pinned?: boolean;
}

// Args for updating user settings for pinned lists
export class SetContactListSettingsDto {
  pinnedListIds?: string[];
  defaultColumns?: ContactListDefaultColumns;
}

// Placeholder views initially rendered by the client
// prior to the summary loading
export const CORE_DEFAULT_CONTACT_VIEWS: ContactView[] = (
  [
    {
      _type: 'default',
      id: 'all',
      size: 0,
      columns: ['name', 'primaryEmail', 'primaryPhoneNumber', 'tags']
    },
    {
      _type: 'default',
      id: 'email',
      size: 0,
      columns: ['name', 'primaryEmail']
    },
    {
      _type: 'default',
      id: 'sms',
      size: 0,
      columns: ['name', 'primaryPhoneNumber']
    }
  ] as unknown[] as IContactView[]
).map((tab: IContactView) => ContactView.fromObject(tab));

export const NEXT_DEFAULT_CONTACT_VIEWS: ContactView[] = (
  [
    {
      _type: 'default',
      id: 'all',
      size: 0,
      columns: ['name', 'primaryEmail', 'mediums']
    }
  ] as unknown[] as IContactView[]
).map((tab: IContactView) => ContactView.fromObject(tab));
